import { useContext, useEffect } from 'react';
import { Breadcrumb, Container } from "../components";
import { GlobalContext } from '../contexts/global.context';
import { Link } from "react-router-dom";

export default function SpeakersPage(){
    const {TEXTS} = useContext(GlobalContext)

    useEffect( () => {
        document.title = `Emer'J | ${TEXTS.speakersPage.breadcrumbTitle}`
    }, [TEXTS.speakersPage.breadcrumbTitle])
    return (
        <>
            <Breadcrumb pageTitle="Speakers" />
            <section className="py-14 lg:py-24">
                <Container className="">
                    <h2 className="text-center text-3xl font-bold">{TEXTS.speakersPage.mainTitle.part1} <span className="text-primary">{TEXTS.speakersPage.mainTitle.part2}</span></h2>
                    <p className="my-10 mx-auto max-w-3xl text-center text-gray-700">{TEXTS.speakersPage.description}</p>

                    <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 ">
                        { TEXTS.speakersPage.speakers.map( ({name, image, position, linkedin}, index) => (
                            <div key={index} className="group bg-white shadow">
                                <div className="relative w-full">
                                    <img className="w-full h-72 object-cover group-hover:blur-[1px]" src={image || "/images/emerj-forum-contact.jpg"} alt="" />
                                    <div className="absolute inset-0 h-full w-full hidden group-hover:flex justify-center items-center gap-x-4 bg-black bg-opacity-70 text-white">
                                        <a className="hover:bg-secondary-deep hover:shadow w-10 h-10 flex justify-center items-center border rounded-full" href={linkedin}> <i className="fab fa-linkedin" /></a>
                                    </div>
                                </div>
                                <div className="py-3 px-4 flex flex-col ">
                                    <h3 className="text-lg font-medium text-center text-primary"><Link to={name.toLowerCase().replaceAll(" ", "-")}>{name}</Link></h3>
                                    <span className="text-center text-secondary">{position}</span>
                                </div>
                            </div>
                        )) }
                    </div>
                </Container>
            </section>
        </>
    )
}