import { useContext, useEffect } from "react"
import { GlobalContext } from '../contexts/global.context';
import { Breadcrumb, Container } from "../components";

export default function ParticipatePage(){
    const {TEXTS} = useContext(GlobalContext)
    useEffect( () => {
        document.title = `Emer'J | ${TEXTS.participatePage.title}`
    }, [TEXTS.participatePage.title])

    return (
        <>
            <Breadcrumb pageTitle={TEXTS.participatePage.title} description={TEXTS.participatePage.description} bgImage="/images/emerj-forum-working-typing-laptop-keyboard.jpg"/>
            <section className="py-14 lg:py-24">
                <Container className="flex flex-col gap-y-10 text-secondary">
                    <h2 className="text-2xl md:text-4xl font-semibold">{TEXTS.participatePage.rewards.title}</h2>
                    <div className="grid sm:grid-cols-2 justify-between gap-5 md:gap-20">
                        { TEXTS.participatePage.rewards.data.map( ({title, description, image}, index) => (
                                <div key={index} className="group hover:bg-secondary hover:text-white hover:shadow-xl p-4 md:py-10 sm:px-2 md:px-4 border border-secondary rounded-md">
                                    <div className="mb-4 flex items-center gap-x-4">
                                        <div className="w-20 h-20 flex flex-wrap justify-center items-center bg-secondary group-hover:bg-white rounded-full shadow-xl">
                                            <img className="w-12 object-contain" src={image} alt="" />
                                        </div>                                        
                                        <h3 className="flex-1 md:text-xl xl:text-2xl font-bold">{title}</h3>
                                    </div>
                                    <p className="text-sm md:text-lg">{description}</p>
                                </div>                                
                            )
                        )}
                    </div>
                </Container>
            </section>
            <section className="py-14 lg:py-24 relative after:absolute after:inset-0 after:w-full after:h-full after:bg-black after:bg-opacity-70">
                <img className="absolute inset-0 w-full h-full object-cover" src="/images/emerj-forum-afro-americans-working-together.jpg" alt="emerj 2023" />
                <Container className="relative z-10 drop-shadow-xl">
                    <h2 className="text-center text-white mb-10 text-2xl md:text-4xl font-semibold">{TEXTS.participatePage.criteria.title}</h2>
                        <div className="w-full flex flex-wrap justify-center gap-4 sm:gap-8 lg:gap-12">
                            { TEXTS.participatePage.criteria.data?.map( (item, index) => (
                                <div key={index} className="bg-secondary-deep bg-opacity-50 text-white md:max-w-lg p-4 sm:px-8 w-full md:w-fit flex flex-col sm:flex-row sm:items-center gap-4 rounded">
                                    <div className="flex justify-center items-center w-14 h-14 rounded-full bg-secondary text-white text-3xl font-semibold">
                                        {index+1}
                                    </div>
                                    <h3 key={index} className="flex-1 text-sm md:text-lg">{item}</h3>
                                </div>
                            ))}
                        </div>
                </Container>
            </section>
            <section className="py-14 lg:py-24">
                <Container className="flex justify-center">
                    <a href="https://www.eventbrite.fr/e/530380120217" target="_blank" className="py-2 px-4 bg-primary hover:bg-secondary-deep text-white text-xl font-semibold rounded" rel="noreferrer">{TEXTS.applyNow}</a>
                </Container>
            </section>
        </>
    )
}