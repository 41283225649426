import { Breadcrumb, Container } from "../components";
import { useContext, useEffect } from 'react';
import { GlobalContext } from '../contexts/global.context';

export default function SchedulePage() {
    const {TEXTS} = useContext(GlobalContext)

    useEffect( () => {
        const headers = document.querySelectorAll(".header")
        const contents = document.querySelectorAll(".content")
        headers.forEach( header => {
            const target = header.getAttribute("data-target")
            header.addEventListener("click", () => {                
                headers.forEach( hd => hd.classList.remove("active"))

                header.classList.add("active")

                contents.forEach( content => {
                    if(content.getAttribute("data-name") === target){
                        content.classList.add("active")
                    }else{
                        content.classList.remove("active")
                    }
                })
                // const targetedContent = document.querySelector(`[data-name=${target}]`)
                // targetedContent?.classList?.add("active")                
            })
        })
    }, [])

    return (
        <>
            <Breadcrumb pageTitle="Programme" />
            <section className="bg-gray-100 py-14 lg:py-24">
                <Container>
                    <h2 className="text-center text-3xl font-bold">Déroulement des <span className="text-primary">activités</span></h2>
                    <p className="my-10 mx-auto max-w-3xl text-center">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusm tempor incididunt ut labore dolore magna aliqua enim ad minim veniam quis nostrud exercitation ullamco.
                    </p>

                    <div className="tabs bg-white shadow">
                        <div className="headers-wrapper">
                            <div className="headers">
                                <div data-target="monday" className="header active">
                                    <div> <i className="fas fa-calendar" /></div>                                
                                    <div className="">
                                        <span>{TEXTS.days.monday}</span>
                                        <span>March 20, 2023</span>
                                    </div>
                                </div>
                                <div data-target="tuesday" className="header">
                                    <div> <i className="fas fa-calendar" /></div>                                
                                    <div className="">
                                        <span>{TEXTS.days.tuesday}</span>
                                        <span>March 20, 2023</span>
                                    </div>
                                </div>
                                <div data-target="wednesday" className="header">
                                    <div> <i className="fas fa-calendar" /></div>                                
                                    <div className="">
                                        <span>{TEXTS.days.wednesday}</span>
                                        <span>March 20, 2023</span>
                                    </div>
                                </div>                 
                            </div>
                            <div className="contents-header">
                                <span>Time</span>
                                <span>Speaker</span>
                                <span>Subject</span>
                                <span>Venue</span>
                            </div>

                        </div>
                        <div className="contents">
                            <div className="content active" data-name="monday">
                                <div className="row">
                                    <div className="time">
                                        <i className="fas fa-clock" />
                                        <span>09:00 AM</span>
                                    </div>
                                    <div className="speaker">
                                        <img src="/images/speakers/emerj-forum-gloire-lomami.png" alt="" />
                                        <span>Gloire LOMAMI</span>
                                    </div>
                                    <div className="subject">Introduction to WP</div>
                                    <div className="venue">Auditorium A</div>
                                </div>
                                <div className="row">
                                    <div className="time">
                                        <i className="fas fa-clock" />
                                        <span>09:00 AM</span>
                                    </div>
                                    <div className="speaker">
                                        <img src="/images/speakers/emerj-forum-gloire-lomami.png" alt="" />
                                        <span>Gloire LOMAMI</span>
                                    </div>
                                    <div className="subject">Introduction to WP</div>
                                    <div className="venue">Auditorium A</div>
                                </div>
                                <div className="row">
                                    <div className="time">
                                        <i className="fas fa-clock" />
                                        <span>09:00 AM</span>
                                    </div>
                                    <div className="speaker">
                                        <img src="/images/speakers/emerj-forum-gloire-lomami.png" alt="" />
                                        <span>Gloire LOMAMI</span>
                                    </div>
                                    <div className="subject">Introduction to WP</div>
                                    <div className="venue">Auditorium A</div>
                                </div>
                                <div className="row">
                                    <div className="time">
                                        <i className="fas fa-clock" />
                                        <span>09:00 AM</span>
                                    </div>
                                    <div className="speaker">
                                        <img src="/images/speakers/emerj-forum-gloire-lomami.png" alt="" />
                                        <span>Gloire LOMAMI</span>
                                    </div>
                                    <div className="subject">Introduction to WP</div>
                                    <div className="venue">Auditorium A</div>
                                </div>
                                <div className="row">
                                    <div className="time">
                                        <i className="fas fa-clock" />
                                        <span>09:00 AM</span>
                                    </div>
                                    <div className="speaker">
                                        <img src="/images/speakers/emerj-forum-gloire-lomami.png" alt="" />
                                        <span>Gloire LOMAMI</span>
                                    </div>
                                    <div className="subject">Introduction to WP</div>
                                    <div className="venue">Auditorium A</div>
                                </div>
                                <div className="row">
                                    <div className="time">
                                        <i className="fas fa-clock" />
                                        <span>09:00 AM</span>
                                    </div>
                                    <div className="speaker">
                                        <img src="/images/speakers/emerj-forum-gloire-lomami.png" alt="" />
                                        <span>Gloire LOMAMI</span>
                                    </div>
                                    <div className="subject">Introduction to WP</div>
                                    <div className="venue">Auditorium A</div>
                                </div>
                                <div className="row">
                                    <div className="time">
                                        <i className="fas fa-clock" />
                                        <span>09:00 AM</span>
                                    </div>
                                    <div className="speaker">
                                        <img src="/images/speakers/emerj-forum-gloire-lomami.png" alt="" />
                                        <span>Gloire LOMAMI</span>
                                    </div>
                                    <div className="subject">Introduction to WP</div>
                                    <div className="venue">Auditorium A</div>
                                </div>
                            </div>
                            <div className="content" data-name="tuesday">
                                <div className="row">
                                    <div className="time">
                                        <i className="fas fa-clock" />
                                        <span>09:00 AM</span>
                                    </div>
                                    <div className="speaker">
                                        <img src="/images/speakers/emerj-forum-emmanuel-lomami.png" alt="" />
                                        <span>Emmanuel LOMAMI</span>
                                    </div>
                                    <div className="subject">Introduction to WP</div>
                                    <div className="venue">Auditorium A</div>
                                </div>
                                <div className="row">
                                    <div className="time">
                                        <i className="fas fa-clock" />
                                        <span>09:00 AM</span>
                                    </div>
                                    <div className="speaker">
                                        <img src="/images/speakers/emerj-forum-gloire-lomami.png" alt="" />
                                        <span>Gloire LOMAMI</span>
                                    </div>
                                    <div className="subject">Introduction to WP</div>
                                    <div className="venue">Auditorium A</div>
                                </div>
                                <div className="row">
                                    <div className="time">
                                        <i className="fas fa-clock" />
                                        <span>09:00 AM</span>
                                    </div>
                                    <div className="speaker">
                                        <img src="/images/speakers/emerj-forum-gloire-lomami.png" alt="" />
                                        <span>Gloire LOMAMI</span>
                                    </div>
                                    <div className="subject">Introduction to WP</div>
                                    <div className="venue">Auditorium A</div>
                                </div>
                                <div className="row">
                                    <div className="time">
                                        <i className="fas fa-clock" />
                                        <span>09:00 AM</span>
                                    </div>
                                    <div className="speaker">
                                        <img src="/images/speakers/emerj-forum-gloire-lomami.png" alt="" />
                                        <span>Gloire LOMAMI</span>
                                    </div>
                                    <div className="subject">Introduction to WP</div>
                                    <div className="venue">Auditorium A</div>
                                </div>
                                <div className="row">
                                    <div className="time">
                                        <i className="fas fa-clock" />
                                        <span>09:00 AM</span>
                                    </div>
                                    <div className="speaker">
                                        <img src="/images/speakers/emerj-forum-gloire-lomami.png" alt="" />
                                        <span>Gloire LOMAMI</span>
                                    </div>
                                    <div className="subject">Introduction to WP</div>
                                    <div className="venue">Auditorium A</div>
                                </div>
                                <div className="row">
                                    <div className="time">
                                        <i className="fas fa-clock" />
                                        <span>09:00 AM</span>
                                    </div>
                                    <div className="speaker">
                                        <img src="/images/speakers/emerj-forum-gloire-lomami.png" alt="" />
                                        <span>Gloire LOMAMI</span>
                                    </div>
                                    <div className="subject">Introduction to WP</div>
                                    <div className="venue">Auditorium A</div>
                                </div>
                                <div className="row">
                                    <div className="time">
                                        <i className="fas fa-clock" />
                                        <span>09:00 AM</span>
                                    </div>
                                    <div className="speaker">
                                        <img src="/images/speakers/emerj-forum-gloire-lomami.png" alt="" />
                                        <span>Gloire LOMAMI</span>
                                    </div>
                                    <div className="subject">Introduction to WP</div>
                                    <div className="venue">Auditorium A</div>
                                </div>
                                <div className="row">
                                    <div className="time">
                                        <i className="fas fa-clock" />
                                        <span>09:00 AM</span>
                                    </div>
                                    <div className="speaker">
                                        <img src="/images/speakers/emerj-forum-emmanuel-lomami.png" alt="" />
                                        <span>Emmanuel LOMAMI</span>
                                    </div>
                                    <div className="subject">Introduction to WP</div>
                                    <div className="venue">Auditorium A</div>
                                </div>
                                <div className="row">
                                    <div className="time">
                                        <i className="fas fa-clock" />
                                        <span>09:00 AM</span>
                                    </div>
                                    <div className="speaker">
                                        <img src="/images/speakers/emerj-forum-gloire-lomami.png" alt="" />
                                        <span>Gloire LOMAMI</span>
                                    </div>
                                    <div className="subject">Introduction to WP</div>
                                    <div className="venue">Auditorium A</div>
                                </div>
                                <div className="row">
                                    <div className="time">
                                        <i className="fas fa-clock" />
                                        <span>09:00 AM</span>
                                    </div>
                                    <div className="speaker">
                                        <img src="/images/speakers/emerj-forum-gloire-lomami.png" alt="" />
                                        <span>Gloire LOMAMI</span>
                                    </div>
                                    <div className="subject">Introduction to WP</div>
                                    <div className="venue">Auditorium A</div>
                                </div>
                                <div className="row">
                                    <div className="time">
                                        <i className="fas fa-clock" />
                                        <span>09:00 AM</span>
                                    </div>
                                    <div className="speaker">
                                        <img src="/images/speakers/emerj-forum-gloire-lomami.png" alt="" />
                                        <span>Gloire LOMAMI</span>
                                    </div>
                                    <div className="subject">Introduction to WP</div>
                                    <div className="venue">Auditorium A</div>
                                </div>
                                <div className="row">
                                    <div className="time">
                                        <i className="fas fa-clock" />
                                        <span>09:00 AM</span>
                                    </div>
                                    <div className="speaker">
                                        <img src="/images/speakers/emerj-forum-gloire-lomami.png" alt="" />
                                        <span>Gloire LOMAMI</span>
                                    </div>
                                    <div className="subject">Introduction to WP</div>
                                    <div className="venue">Auditorium A</div>
                                </div>
                                <div className="row">
                                    <div className="time">
                                        <i className="fas fa-clock" />
                                        <span>09:00 AM</span>
                                    </div>
                                    <div className="speaker">
                                        <img src="/images/speakers/emerj-forum-gloire-lomami.png" alt="" />
                                        <span>Gloire LOMAMI</span>
                                    </div>
                                    <div className="subject">Introduction to WP</div>
                                    <div className="venue">Auditorium A</div>
                                </div>
                                <div className="row">
                                    <div className="time">
                                        <i className="fas fa-clock" />
                                        <span>09:00 AM</span>
                                    </div>
                                    <div className="speaker">
                                        <img src="/images/speakers/emerj-forum-gloire-lomami.png" alt="" />
                                        <span>Gloire LOMAMI</span>
                                    </div>
                                    <div className="subject">Introduction to WP</div>
                                    <div className="venue">Auditorium A</div>
                                </div>
                            </div>
                            <div className="content" data-name="wednesday">
                                <div className="row">
                                    <div className="time">
                                        <i className="fas fa-clock" />
                                        <span>09:00 AM</span>
                                    </div>
                                    <div className="speaker">
                                        <img src="/images/speakers/emerj-forum-gloire-lomami.png" alt="" />
                                        <span>Gloire LOMAMI</span>
                                    </div>
                                    <div className="subject">Introduction to WP</div>
                                    <div className="venue">Auditorium A</div>
                                </div>
                                <div className="row">
                                    <div className="time">
                                        <i className="fas fa-clock" />
                                        <span>09:00 AM</span>
                                    </div>
                                    <div className="speaker">
                                        <img src="/images/speakers/emerj-forum-gloire-lomami.png" alt="" />
                                        <span>Gloire LOMAMI</span>
                                    </div>
                                    <div className="subject">Introduction to WP</div>
                                    <div className="venue">Auditorium A</div>
                                </div>
                                <div className="row">
                                    <div className="time">
                                        <i className="fas fa-clock" />
                                        <span>09:00 AM</span>
                                    </div>
                                    <div className="speaker">
                                        <img src="/images/speakers/emerj-forum-gloire-lomami.png" alt="" />
                                        <span>Gloire LOMAMI</span>
                                    </div>
                                    <div className="subject">Introduction to WP</div>
                                    <div className="venue">Auditorium A</div>
                                </div>
                                <div className="row">
                                    <div className="time">
                                        <i className="fas fa-clock" />
                                        <span>09:00 AM</span>
                                    </div>
                                    <div className="speaker">
                                        <img src="/images/speakers/emerj-forum-gloire-lomami.png" alt="" />
                                        <span>Gloire LOMAMI</span>
                                    </div>
                                    <div className="subject">Introduction to WP</div>
                                    <div className="venue">Auditorium A</div>
                                </div>
                                <div className="row">
                                    <div className="time">
                                        <i className="fas fa-clock" />
                                        <span>09:00 AM</span>
                                    </div>
                                    <div className="speaker">
                                        <img src="/images/speakers/emerj-forum-gloire-lomami.png" alt="" />
                                        <span>Gloire LOMAMI</span>
                                    </div>
                                    <div className="subject">Introduction to WP</div>
                                    <div className="venue">Auditorium A</div>
                                </div>
                                <div className="row">
                                    <div className="time">
                                        <i className="fas fa-clock" />
                                        <span>09:00 AM</span>
                                    </div>
                                    <div className="speaker">
                                        <img src="/images/speakers/emerj-forum-gloire-lomami.png" alt="" />
                                        <span>Gloire LOMAMI</span>
                                    </div>
                                    <div className="subject">Introduction to WP</div>
                                    <div className="venue">Auditorium A</div>
                                </div>
                                <div className="row">
                                    <div className="time">
                                        <i className="fas fa-clock" />
                                        <span>09:00 AM</span>
                                    </div>
                                    <div className="speaker">
                                        <img src="/images/speakers/emerj-forum-gloire-lomami.png" alt="" />
                                        <span>Gloire LOMAMI</span>
                                    </div>
                                    <div className="subject">Introduction to WP</div>
                                    <div className="venue">Auditorium A</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
};
