import { Link } from "react-router-dom"
import Container from "./container"
import { useContext } from 'react';
import { GlobalContext } from '../contexts/global.context';

type props = {
    bgImage? : string
    bgImagePosition? : "top" | "bottom" | "left" | "right" | "center" |  "top left" | "top right" | "bottom left" | "bottom right"
    pageTitle : string
    description? : string
}
export default function Breadcrumb({pageTitle,description, bgImage = "/images/emerj-forum-work-session.jpg", bgImagePosition} : props){
    const {TEXTS} = useContext(GlobalContext)
    return (
        <section className="relative flex items-center after:absolute after:inset-0 after:w-full after:h-full after:bg-black after:bg-opacity-70">
            <img src={bgImage} className={`absolute inset-0 w-full h-full object-cover object-${bgImagePosition}`} alt="" />
            <Container className="w-full py-14 relative z-10 min-h-[400px] xl:min-h-[550px] 2xl:min-h-[700px] flex flex-col justify-center gap-y-4 text-white drop-shadow-xl">
                <h1 className="text-2xl md:text-4xl font-bold uppercase text-primary">{pageTitle}</h1>
                <p className="max-w-6xl text-sm md:text-lg xl:text-2xl text-gray-200">{description}</p>
                <div className="absolute bottom-0 translate-y-1/2 w-fit py-3 px-8 rounded bg-secondary-deep text-sm md:text-lg flex items-center gap-x-3"><Link to="/" className="font-bold">{TEXTS.menu[0].label}</Link> <i className="fas fa-angle-right" /> {pageTitle} </div>
            </Container>
        </section>
    )
}