import { useContext, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Container } from "../components";
import { GlobalContext } from '../contexts/global.context';


export default function HomePage(){
    const {TEXTS} = useContext(GlobalContext)

    useEffect( () => {
        document.title = `Emer'J | ${TEXTS.menu[0].label}`
    }, [TEXTS.menu])
    
    return (
        <>
            <section className="relative grid items-center text-white min-h-screen xs:min-h-[calc(100vh-6rem)] after:absolute after:inset-0 after:w-full after:h-full after:bg-black after:bg-opacity-70">
                <img src="/images/emerj-forum-brainstorming.jpg" alt="" className="absolute inset-0 w-full h-full object-cover" />
                <Container className="text-center relative z-10 flex flex-col justify-center items-center gap-y-5 lg:gap-y-10">
                    <h1 className="text-primary text-center text-2xl md:text-5xl xl:text-7xl font-bold drop-shadow-md">{TEXTS.homePage.hero.title}</h1>
                    <h3 className="text-xl md:text-2xl font-semibold drop-shadow-md text-center">{TEXTS.homePage.hero.subTitle}</h3>
                    <p className="text-primary text-xl text-center font-bold drop-shadow-md">{TEXTS.homePage.hero.p}</p>
                    <a href="https://www.eventbrite.fr/e/530380120217" target="_blank" className="uppercase w-fit px-8 py-3 font-semibold bg-primary hover:bg-secondary-deep rounded text-white" rel="noreferrer">{TEXTS.registerNow}</a>
                </Container>
            </section>

            <section className="py-14 lg:py-24 bg-secondary-deep bg-opacity-10">
                <Container className="">
                    <h2 className="mx-auto text-secondary  max-w-3xl text-center text-xl md:text-3xl font-semibold">{TEXTS.homePage.higlights.title}</h2>
                    <div className="mt-10 grid xs:grid-cols-2 md:grid-cols-3 gap-5 lg:gap-10">
                        { TEXTS.homePage.higlights.data.map( ({label, icon}, index) => (
                            <div className="py-4 sm:py-10 border-2 border-transparent hover:border-opacity-30 rounded-md bg-secondary bg-opacity-80 hover:bg-secondary text-white hover:text-primary hover:border-secondary-deep flex flex-col items-center gap-y-4" key={index}>
                                <img className="h-12 sm:h-20 object-contain" src={`/images/icons/${icon}`} alt="" />
                                <h3 className="text-xs sm:text-xl text-center font-bold">{label}</h3>
                            </div>
                        ))}
                    </div>
                </Container>
            </section>
            <section className="py-14 lg:py-24">
                <Container>
                    <h2 className="mx-auto text-primary max-w-3xl text-center text-3xl font-semibold">{TEXTS.speakersPage.breadcrumbTitle}</h2>
                    <div className="mt-10 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 ">
                        { TEXTS.speakersPage.speakers.map( ({name, image, position, linkedin}, index) => (
                            <div key={index} className="group bg-white shadow">
                                <div className="relative w-full">
                                    <img className="w-full h-72 object-cover group-hover:blur-[1px]" src={image || "/images/emerj-forum-contact.jpg"} alt="" />
                                    <div className="absolute inset-0 h-full w-full hidden group-hover:flex justify-center items-center gap-x-4 bg-black bg-opacity-70 text-white">
                                        <a className="hover:bg-secondary-deep hover:shadow w-10 h-10 flex justify-center items-center border rounded-full" href={linkedin}> <i className="fab fa-linkedin" /></a>
                                    </div>
                                </div>
                                <div className="py-3 px-4 flex flex-col ">
                                    <h3 className="text-lg font-medium text-center text-primary"><Link to={name.toLowerCase().replaceAll(" ", "-")}>{name}</Link></h3>
                                    <span className="text-center text-secondary">{position}</span>
                                </div>
                            </div>
                        )) }
                    </div>
                </Container>
            </section>
            <section className="sm:py-14 lg:py-24 relative after:absolute after:inset-0 after:w-full after:h-full after:bg-black after:bg-opacity-60">
                <img className="absolute inset-0 w-full h-full object-cover" src="/images/emerj-forum-classroom.jpg" alt="emerj 2023" />
                <Container className="mx-0 px-0 relative z-10 text-white ">
                    <div className="py-14 md:py-0 grid gap-y-2 md:gap-y-4 w-full xl:w-1/2 bg-black md:bg-secondary-deep md:bg-opacity-50 bg-opacity-50 md:rounded-md px-4 md:p-8">
                        <h2 className="text-center md:text-left text-3xl font-semibold text-primary md:text-white">{TEXTS.homePage.attend.section1.title}</h2>
                        <ul className="list-none text-lg">
                            { TEXTS.homePage.attend.section1.data.map( (item, index) => (
                                <li className="drop-shadow-xl flex items-baseline gap-2 py-3 md:py-8 even:border-y-2 border-primary md:border-secondary-deep border-opacity-30 md:border-opacity-30" key={index}>
                                    <span className="rounded-full w-8 h-8 text-[.75rem] bg-secondary flex justify-center items-center shadow-xl">
                                        <i className="fas fa-check" />
                                    </span>
                                    <span className="flex-1">{item}</span>
                                </li>
                            )) }
                        </ul>
                    </div>
                </Container>
            </section>
            <section className="py-14 lg:py-24">
                <Container className="grid gap-y-10">
                    <h2 className="text-3xl font-semibold text-center text-primary">{TEXTS.homePage.sponsors.title}</h2>
                    <div className="flex flex-wrap items-center justify-center gap-x-6 md:gap-6">
                        <a target="_blank" rel="noreferrer" className="group p-2 border-secondary rounded-md" href="https://icashbs.com/">
                            <img className="grayscale group-hover:grayscale-0 h-16 md:h-24 object-cover" src="/images/partners/emerj-forum-icashbs.png" alt="" />
                        </a>
                        <a target="_blank" rel="noreferrer" className="group p-2 border-secondary rounded-md" href="https://www.facebook.com/TendancesScenic/">
                            <img className="grayscale group-hover:grayscale-0 h-16 md:h-24 object-cover" src="/images/partners/emerj-forum-tendaces.png" alt="" />
                        </a>
                        <a target="_blank" rel="noreferrer" className="hidden group p-2 border-secondary rounded-md" href="https://fayahotel.com//">
                            <img className="grayscale group-hover:grayscale-0 h-16 md:h-24 object-cover" src="/images/partners/emerj-forum-tendaces.png" alt="" />
                        </a>
                    </div>
                </Container>
            </section>

            <section className="py-14 lg:py-24 bg-secondary-deep bg-opacity-10">
                <Container className="grid gap-y-16">
                    <h2 className="mx-auto max-w-3xl text-center text-3xl font-semibold text-secondary">{TEXTS.homePage.attend.section2.title}</h2>
                    <div className=" flex flex-wrap justify-center gap-5 lg:gap-8 xl:gap-24 2xl:gap-20">
                        { TEXTS.homePage.attend.section2.data1.map( ({label, icon}, index) => (
                            <div key={index} className="group text-white  bg-secondary bg-opacity-80 hover:bg-secondary py-4 px-2 w-28 xxs:w-32 sm::w-52 lg:w-48  xl:h-52 flex flex-col items-center justify-center text-center gap-y-2 md:gap-y-5 rounded">
                                <img className="grayscale h-12 sm:h-16 object-contain" src={`/images/icons/${icon}`} alt="" />
                                <h3 className="text-xs sm:text-xl font-semibold">{label}</h3>
                            </div>
                        )) }
                    </div>
                </Container>
            </section>       

            <section className="md:py-14 lg:py-24 relative after:absolute after:inset-0 after:w-full after:h-full after:bg-black after:bg-opacity-60">
                <img className="absolute inset-0 w-full h-full object-cover" src="/images/emerj-forum-classroom.jpg" alt="" />
                <Container className="relative z-10 mx-0 px-0 grid items-center md:grid-cols-2 md:gap-10 text-white">
                    <div className="py-14 px-4 md:p-0">
                        <p className=" text-lg lg:text-3xl xl:text-4xl font-semibold drop-shadow md:drop-shadow-none">{TEXTS.homePage.attend.section2.description}</p>
                    </div>
                    <div className="py-14 md:py-0 md:my-0 px-4 flex justify-center bg-black bg-opacity-60 md:bg-transparent">
                        <ul className="w-fit list-none flex flex-col gap-y-5 ">
                            { TEXTS.homePage.attend.section2.data2.map( (item, index) => (
                                <li className="drop-shadow-xl flex items-center gap-2 p-3 bg-secondary-deep bg-opacity-70 md:bg-primary md:bg-opacity-40 rounded" key={index}>
                                    <span className="rounded-full w-8 h-8 text-[.75rem] bg-secondary md:bg-white md:bg-opacity-50 flex justify-center items-center shadow-2xl">
                                        <i className="fas fa-check" />
                                    </span>
                                    <span className="flex-1 ">{item}</span>
                                </li>
                            )) }
                        </ul>
                    </div>
                </Container>
            </section>
        </>
    )
}