import { Breadcrumb, Container, SubscribeComponent } from "../components";
import { useEffect, useContext } from 'react';
import { GlobalContext } from '../contexts/global.context';

export default function AboutUsPage(){
    const {TEXTS} = useContext(GlobalContext)
    useEffect( () => {

        document.title = `Emer'J | ${TEXTS.aboutUsPage.title}`
    }, [TEXTS.aboutUsPage.title])

    return (
        <>
            <Breadcrumb pageTitle={TEXTS.aboutUsPage.title} description={TEXTS.aboutUsPage.descriptionAbout}/>
            
            <section className="py-14 lg:py-24">
                <Container className="grid items-center lg:grid-cols-2 gap-y-5 gap-x-10">
                    <div className="flex flex-col gap-y-5">
                        <h2 className="text-2xl md:text-4xl font-semibold">{TEXTS.aboutUsPage.organisation.title}</h2>
                        <h3 className="text-primary text-2xl md:text-4xl font-bold">Emer'J Academy</h3>
                        <div className="text-secondary flex flex-col gap-y-4">                            
                            <p className="sm:text-lg">{TEXTS.aboutUsPage.organisation.description}</p>
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <ul className="w-full md:w-fit grid md:grid-cols-2 lg:grid-cols-1 gap-6">
                            { TEXTS.aboutUsPage.organisation.data?.map( (item, index) => (
                                <li key={index} className="rounded md:max-w-md text-sm md:text-lg text-center py-2 px-4 sm:px-8 bg-primary sm:bg-secondary-deep text-white">{item}</li>
                            ))}
                        </ul>
                    </div>
                </Container>
            </section>
            <section className="py-14 lg:py-24 bg-secondary-deep relative after:absolute after:inset-0 after:w-full after:h-full after:bg-black after:bg-opacity-60 after:md:bg-opacity-60">
                <img className="absolute inset-0 w-full h-full object-cover" src="/images/emerj-forum-classroom.jpg" alt="emerj 2023" />
                <Container className="drop-shadow-2xl relative z-10 flex flex-col gap-y-10 xl:gap-y-14 2xl:gap-y-16">
                    <h2 className="drop-shadow-xl text-center text-white text-3xl md:text-4xl font-bold">{TEXTS.aboutUsPage.activities.title}</h2>
                    <div className="grid sm:grid-cols-2 justify-between gap-5 md:gap-20">
                        { TEXTS.aboutUsPage.activities.data.map( ({title, description, slug}, index) => (
                                <div key={index} className="bg-secondary bg-opacity-50 hover:bg-opacity-100 border-black border-opacity-5 border rounded text-white p-4 md:py-10 sm:px-2 md:px-4 shadow-xl">
                                    <h3 className="md:text-xl font-bold mb-2">{title}</h3>
                                    <p className="text-sm md:text-lg">{description}</p>
                                </div>                                
                            )
                        )}

                    </div>
                </Container>
            </section>

            {/* <section className="py-14 lg:py-24"></section> */}
            
            {/* <SubscribeComponent /> */}
        </>
    )
}