import { Link } from "react-router-dom";
import { Container } from "../components";
import { useContext } from 'react';
import { GlobalContext } from '../contexts/global.context';

export default function PageNotFound(){
    const {TEXTS} = useContext(GlobalContext)
    return (
        <>
            <section className="min-h-[calc(100vh-13.4rem)] md:min-h-[calc(100vh-8.9rem)] grid items-center">
                <Container className="">
                    <h2 className="text-9xl lg:text-[13rem] leading-none text-red-600 font-bold text-center">404</h2>
                    <p className="my-6 text-center md:text-lg md:font-semibold">{TEXTS.pageNotFound.text1}</p>
                    <p className="text-center"><Link to="/" className="text-white bg-primary hover:bg-secondary-deep px-6 py-2 border rounded">{TEXTS.pageNotFound.text2.brillant}<i className="ml-2 fas fa-house" /> </Link> </p>
                </Container>
            </section>
        </>
    )
}