import { BrowserRouter, Routes, Route } from "react-router-dom"
import GlobalContextProvider from "./contexts/global.context";
import { Footer, Header } from "./components";
import { HomePage, AboutUsPage, SpeakersPage, PageNotFound, SchedulePage, EmerjTour, ContactPage, ParticipatePage } from "./pages";

export default function App() {
  return (
    <GlobalContextProvider>      
      <BrowserRouter >
        <Header />
        <Routes>
          <Route path="" element={<HomePage />} />
          <Route path="about-us" element={<AboutUsPage />} />
          <Route path="participate" element={<ParticipatePage />} />
          <Route path="speakers/*" element={<SpeakersPage />} />
          <Route path="emerj-tour" element={<EmerjTour />} />
          <Route path="schedule" element={<SchedulePage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </GlobalContextProvider>
  );
}
