import { Link } from "react-router-dom"

type props = {
    className? : string
}
export default function Logo({className} : props) {
    return (
        <Link to="/" className="w-fit">
            <img className=" -translate-x-2 w-24 md:w-36 h-10 md:h-14 object-cover" src="/images/logos/emerj-forum-logo.png" alt="" />
        </Link>
    )
};
