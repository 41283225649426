import { useContext } from 'react';
import { GlobalContext } from '../contexts/global.context';
import { Breadcrumb } from '../components';
export default function EmerjTour(){
    const {TEXTS} = useContext(GlobalContext)
    return (
        <>
            <Breadcrumb pageTitle="Emer'J Tour 2023" bgImage="/images/emerj-forum-afro-americans-working-together.jpg" />
            <section className="">

            </section>
        </>
    )
}