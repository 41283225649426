import { Breadcrumb, Container } from "../components";
import { useContext, useEffect } from 'react';
import { GlobalContext } from '../contexts/global.context';

export default function ContactPage() {
    const {TEXTS} = useContext(GlobalContext)

    useEffect( () => {
        document.title = `Emer'J | ${TEXTS.contactPage.breadcrumbTitle}`
    }, [TEXTS.contactPage.breadcrumbTitle])
    return (
        <>
            <Breadcrumb pageTitle={TEXTS.contactPage.breadcrumbTitle} bgImage="/images/emerj-forum-contact.jpg" />
            <section className="py-14 lg:py-24 bg-white text-secondary">
                <Container>
                    <h1 className="text-2xl lg:text-3xl font-semibold ">Faites nous part de vos préoccupations</h1>
                    <p className="md:text-sm lg:text-lg ">
                        At the <span className="text-primary font-semibold">EMMER'J Forum</span>, we understand that in today's business environment, it is increasingly 
                        important to <span className="italic font-semibold">use digital technology to stay competitive and grow a business</span>. That's why we offer a 
                        unique forum to connect and collaborate with other young business leaders who want to get the most out of their business with the help of digital 
                        technology. Unlock the power of digital technology to unlock your company's success at the EMMER'J Forum.
                    </p>
                    <div className="mt-10 flex flex-col md:flex-row gap-6 lg:gap-x-10">
                        <div className="w-full md:w-1/3 lg:w-1/2">
                            <img className="w-full h-72 md:h-96 object-cover rounded-xl shadow-xl" src="/images/emerj-forum-contact.jpg" alt="" />
                        </div>
                        <div className="flex flex-col w-full md:w-2/3 lg:w-1/2">
                            <h3 className="text-2xl">Nos coordonnées</h3>
                            <a href="mailto:info@global-travel-services-cd.com" className="hover:text-primary hover:scale-110 hover:translate-x-11 py-4">
                                <i className="text-xl mr-3 fas fa-envelope" />
                                info@icashbs.com
                            </a>
                            <a href="tel:+14189067326" className="border-t hover:text-primary hover:scale-110 hover:translate-x-11 py-4">
                                <i className="mr-3 fas fa-phone" />
                                +1 418 906 7326
                            </a>
                            <div className="hover:text-primary hover:scale-110 hover:translate-x-11 py-4 border-y">
                                <i className="text-xl mr-3 fas fa-map" />
                                625 Chateauguay, Québec, QC, Canada
                            </div>
                            <a href="tel:+243816122902" className="hover:text-primary hover:scale-110 hover:translate-x-11 py-4 border-y">
                                <i className="mr-3 fas fa-phone" />
                                +243 816 122 902
                            </a>
                            <div className="hover:text-primary hover:scale-110 hover:translate-x-11 py-4 border-b">
                                <i className="text-xl mr-3 fas fa-map" />
                                3, Av. Bas-congo, Kinshasa/Gombé
                            </div>
                            <div className="pt-4 flex items-center gap-8">
                                <a className="hover:text-primary hover:scale-125 font-bold rounded-full" href="https://www.facebook.com/globaltraelservices" target="_blank" rel="noreferrer">
                                    <i className="text-2xl md:text-4xl fab fa-facebook" />
                                </a>
                            </div>
                        </div>

                    </div>
                </Container>
            </section>
        </>
    )
};
