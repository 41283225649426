import Container from "./container";

export default function Footer(){
    return(
        <footer className="py-4 bg-secondary-deep text-white">
            <Container className=" text-sm flex flex-col-reverse gap-y-4 items-center sm:flex-row justify-between">
                <span className="italic">Copyright <i className="fas fa-copyright" /> { new Date(Date.now()).getFullYear() }. powered by <a className="text-primary font-semibold" href="https://www.icashbs.com" target="_blank" rel="noreferrer" >ICASHBS</a> </span>
                <div className="flex flex-wrap justify-center gap-y-4 gap-x-4 sm:gap-x-8">
                    <a href="tel:+14189067326" className="hover:text-primary flex items-center gap-1 sm:gap-4">
                       <i className="text-3xl fas fa-phone" />
                       <span>+1 418 906 7326</span>
                    </a>
                    <a href="mailto:info@icashbs.com" className="hover:text-primary flex items-center gap-1 sm:gap-4">
                       <i className="text-3xl fas fa-envelope" />
                       <span>info@icashbs.com</span>
                    </a>
                </div>
            </Container>
        </footer>
    )
}