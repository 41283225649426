export default interface TextContent {
    menu : {label : string, link : string}[]
    homePage : {
        hero : {
            title : string,
            subTitle : string
            p : string
        }
        higlights : {
            title : string
            data : {label : string, icon : string}[]
        }
        attend : {
            section1 : { title : string, data : string[] }
            section2 : {
                title : string
                description : string,
                data1 : {label : string, icon : string}[],
                data2 : string[]
            }
        }
        sponsors : {
            title : string
        }
    }
    speakersPage : {
        breadcrumbTitle : string
        mainTitle : { part1 : string, part2 : string}
        description : string
        speakers : { name : string, position : string, biography : string, image : string, linkedin : string } []
    }
    emerjTour? : {
        breadcrumbTitle : string
    }
    contactPage : {
        breadcrumbTitle : string
    } 
    aboutUsPage : {
        title : string
        descriptionAbout : string
        organisation : {
            title : string
            description : string
            data? : string[]
        }
        activities : {
            title : string
            data : {title : string, description : string, slug? : string}[]
        }
    }
    participatePage:{
        title : string
        description : string
        rewards : {
            title : string
            data : {image : string, title : string, description : string}[]
        }
        criteria : {
            title : string
            data : string[]
        }
    }
    pageNotFound : {
        text1 : string
        text2 : { text : string, brillant : string }
    }
    registerNow : string
    days : {
        monday : string
        tuesday : string
        wednesday : string
        thursday : string
    }
    applyNow : string
}

export const TEXT_EN : TextContent=  {
    menu : [
        { label : "Home", link : "/"},
        // { label : "Speakers", link : "/speakers"},
        { label : "About", link : "/about-us"},
        // { label : "Emer'J Tour", link : "/emerj-tour"},
        { label : "Participate", link : "/participate"},
        { label : "Contact", link : "/contact"},
    ],
    homePage : {
        hero : {
            title : "EMMER'J FORUM 2023",
            subTitle : "Leadership, Digital Transformation & Innovation",
            p : "26-28 April Douala, Cameroon"
        },
        higlights : {
            title : "Be part of the movement that is revolutionizing entrepreneurship in Africa.",
            data : [
                { label : "3 Days", icon : "timing.png"},
                { label : "2 Webinars", icon : "schema.png"},
                { label : "20+ Speakers", icon : "speakers.png"},
                { label : "5+ Discussion Panels", icon : "messages.png"},
                { label : "Solution Demos", icon : "teaching.png"},
                { label : "Masterclasses", icon : "masterclasse.png"},
            ]
        },
        attend : {
            section1 : {                
                title  : "Why Attend ?",
                data : [
                    "To understand in depth the development and benefits of digital transformation in Africa.",
                    "Get in touch with the influential actors of the EMMER'J Academy and digital transformation, the trendsetters of the digital movement in Africa.",
                    "Attend product and service exhibitions that are revolutionizing payments, transfers, communications, digital marketing, and more."
                ]
            },
            section2 : {
                title : "Who's Attending ?",
                description : "Given the focus on digital transformation, entities of all kinds have now made financial inclusion in Africa a priority. Therefore, we bring together experts from a wide variety of sectors to collaborate on this issue.",
                data1 : [
                    { label : "Financial institutions", icon : "emerj-financial-institutions.png"},
                    { label : "Regulators", icon : "iemerj-regulators.png"},
                    { label : "Fintechs", icon : "emerjs-fintechs.png"},
                    { label : "Technology Solutions", icon : "masterclasse.png"},
                    { label : "Tech Industries", icon : "emerj-tsp.png"},
                    { label : "Governing bodies", icon : "emerj-bodies.png"},
                    { label : "Investors", icon : "emerj-investors.png"},
                ],
                data2 : [
                    "Leaders in innovation and digital transformation",
                    "Key Business Decision-Makers",
                    "Founders and CEOs of Fintechs",
                    "Entrepreners",
                    "Senior managers of institutions"
                ]
            }
        },
        sponsors : {
            title : "They Support Us"
        }
    },
    speakersPage : {
        breadcrumbTitle : "Our speakers",
        mainTitle : { part1 : "Who", part2 : "Speaking" },
        description : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusm tempor incididunt ut labore dolore magna aliqua enim ad minim veniam quis nostrud exercitation ullamco.",
        speakers : [
            {
                name : "Gloire LOMAMI",
                position : "VP ICASHBS",
                image : "/images/speakers/emerj-forum-gloire-lomami.png",
                biography : "Si j’avais à me décrire en quelques lignes, je dirais que je suis une personne remplie d’audace, de confiance et de potentiel que je m’efforce de faire croître dans la mesure du réalisable. Épris de passion pour les casse-têtes, j’ai su trouver dans le domaine des TIC matière à la hauteur de mon appétit.\nJ’adore la gestion et la réalisation des projets. Parfois je me perds entre mes chapeaux de Gestionnaire, architecte de solutions et de Lead dev. Je crois en la force d'une équipe soudée et je m'évertue à créer un environnement optimal et agréable de travail.\nJ’adore autant partager que recevoir.\nCe qui fait ma particularité, en tant que personne, c’est mon respect pour autrui, ma dynamique de vie, mon sens des responsabilités et surtout mon sens prononcé d’humour. Pour demain, je veux faire grandir mes entreprises et impacter positivement ceux qui m’entourent.",
                linkedin : "https://www.linkedin.com/in/lom-s-lomami-b95b7127/",
            },
            {
                name : "Emmanuel LOMAMI",
                position : "Managing Director ICASHBS",
                image : "/images/speakers/emerj-forum-emmanuel-lomami.png",
                biography : "An enthusiastic and dedicated IT professional with over 10 years' experience in project and team management with comprehensible expertise in directing the implementation of wide variety of complex IT Systems and solutions. Piloted and worked part of the sophisticated technology projects, developing product requirements, and analysing support strategies and processes to facilitate improved operations and efficiency. Applied expertise in developing and leading projects and initiatives for major commercial and international organisations including Citibank, Almac group, Verint, Weatherbys Bank, Barclaycard, Alliance Irish Bank (AIB) and TPICAP. Provided essential consultative support and building key client relationships through effective interpersonal, organisational and leadership abilities. With a proven track record as a freelancer, in leading project management teams through technical installations and development cycles of systems and programs for a broad range of clients such as icashbs.co.uk, kingsridgecity.com, invesperia.ca, yayo-africa.com and icash-congo.com. Possess excellent interpersonal skills and experience of liaising with the business with strong communication skills and can communicate concisely at all levels. Combined with problem solving skills, has been proven essential to excel in driving teams through all facets of project life cycles and ensuring optimal client experiences.",
                linkedin : "https://www.linkedin.com/in/emmanuel-kasongo-a1581411/",
            },
            // {
            //     name : "Vanessa MBAMARAH",
            //     position : "Founder Ztallion",
            //     biography : "Vanessa Is a Marketing and Transformation Specialist, a speaker, trainer, philanthropist and a digital enthusiast who is passionate about bridging the digital skills gap in Africa. After undergoing the Google Digital Skills training in 2016, Vanessa went on to pioneer the #DigifyBytes Training Program in Cotonou Benin, an initiative of Google to train 1 Million Africans on Digital skills in 2016. Six months later, she was selected and featured as one of Google’s First Success story on the Google Digital Skills Program in Africa, before becoming a training partner on the same program representing Francophone Africa.",
            //     linkedin : "https://www.linkedin.com/in/vmbamarah/",
            //     image : "/images/speakers/emerj-forum-vanessa-mbamarah.jpg",
            // },
            {
                name : "Dicko SY",
                position : "CO-FONDATRICE DICTAF",
                biography : "Agronome et communicante, Dicko est une jeune femme qui a décidé de consacrer toute sa vie au développement de l'agriculture Sénégalaise. Diplômée de l'université Gaston Berger de Saint-Louis en sciences agronomiques, elle s'est vite spécialisée en production végétale afin de voir comment participer à la hausse des rendements de récolte pour conduire le pays à l'autosuffisance alimentaire. Particulièrement sensible à l'implication des femmes dans le processus de développement, Dicko encourage les filles à se lancer dans l'entreprenariat dans le secteur agricole. Plusieurs distinctions lui ont été décernées au courant de son combat, l'on peut citer le Prix de l'entreprenariat numérique féminin de la Sonatel: Linguere Digital Challenge. Elle fait partie des Cinq jeunes entrepreneurs sénégalais sur 1000 Africains qui ont été retenus par la Fondation Tony Elumelu dans le cadre de la 3ème édition du Programme d’entreprenariat dénommé Tony Elumelu entrepreneurship programme (Teep). Ambitieuse, dynamique avec une joie de vivre débordante, elle inspire confiance aux clients et partenaires.",
                linkedin : "https://www.linkedin.com/in/dicko-sy-60363b111/",
                image : "/images/speakers/emerj-forum-dicko-sy.jpeg",
            },
            {
                name : "Jean-François BOILY",
                position : "DÉVELOPPER SÉNIOR",
                biography : "Jean-François est passionné de programmation depuis… très très longtemps. Diplômé de l’Université de Sherbrooke en 1997, son parcours professionnel l’a mené en jeux vidéo, web, consultation et enseignement, suivant le fil de ses intérêts. Toujours porté par l’innovation, l’apprentissage et le partage, il sait transmettre sa passion et son entrain est contagieux.",
                linkedin : "https://www.linkedin.com/in/jeanfrancoisboily/",
                image : "/images/speakers/emerj-forum-jean-francois-boilly.jpeg",
            },
            {
                name : "Laurence GRAVEL",
                position : "Scrum Master, Project Lead chez Vanderlande",
                biography : "En tant que Scrum Master, mon rôle est d'assurer une bonne collaboration entre nos équipes à l'international et de contribuer à une transformation vers le management Agile. Mon objectif est de faire passer le développement logiciel au niveau supérieur en coachant les équipes vers l'adoption de pratiques de travail plus efficaces. Je dois également m'assurer de construire un environnement de confiance et de sécurité au sein des équipes pour favoriser les discussions, la prise de décisions et la résolution de conflits. Je suis animée par l'ambition de devenir la meilleure version de moi-même et une meilleure leader pour mes pairs. Je crois que je peux réaliser mes ambitions en apprenant des gens intelligents et compétents qui m'entourent. Car l'excellence est un effort d'équipe.",
                linkedin : "https://www.linkedin.com/in/laurence-gravel-32887ba5/",
                image : "/images/speakers/emerj-forum-laurence-gravel.jpg",
            },
            {
                name : "Maxime BELANGER",
                position : "Sofwtare developpeur sénior",
                biography :  "",
                linkedin : "https://www.linkedin.com/in/maximebelanger",
                image : "/images/speakers/emerj-forum-maxime-belanger.jpg",
            },
            {
                name : "Elyy NDIMBU",
                position : "Fondateur Groupe Kin’s Dreams",
                biography :  "",
                linkedin : "https://www.linkedin.com/in/elie-ndibu-228270145/",
                image : "/images/speakers/emerj-forum-ely-ndimbu.jpg",
            }
        ]
    },
    aboutUsPage : {
        title : "About Emer'J",
        descriptionAbout : "Emerj Academy is hosting the 'Emerj Forum' from April 26-28, 2023, a three-day event dedicated to presenting the Perspective Ground Zero of the Digital Transformation. Furthermore, participants will have the opportunity to explore various topics related to the Fintech sectors, business & technology practices, as well as regulations & policies that enable entrepreneurs to thrive on a global scale. This thought-provoking gathering will include panel discussions, solution demos, expo booths with the latest products, and engaging fireside chats.",
        organisation : {
            title : "",
            description : "Emer'J Academy is committed to empowering aspiring African entrepreneurs to meet the ever-changing demands of the digital space. Founded with the goal of giving entrepreneurs the know-how and skills needed to succeed in the digital transformation, we provide a range of courses covering IT and management practices to equip anyone with the ambition and dedication to gain the necessary knowledge to succeed. Our unique curriculum ensures that our students have the tools and confidence to excel in their chosen field, driving positive changes to their local community. With Emer'J Academy, young entrepreneurs have the opportunity to become experts in the digital transformation industry in Africa - we invite you to join us and realize your entrepreneurial dreams.",
            data : ["Realize your entrepreneurial dreams with Emer'J Academy", "Develop the skills to succeed in the digital transformation industry", "Unique curriculum that provides the necessary know-how and tools", "Be part of a growing community of successful African entrepreneurs"]
            // data : ["Amongst the Largest Players in this segment in Asia", "Have Mentored Leading Founders & Digital Leaders", "Recognized as strong influencers on Fintech, Digital & Open Banking", "Sizeable Network in Banking and Fintech Industry"]
        },
        activities : {
            title : "Our activities",
            data : [
                {slug : "", title : "EMMER'J ACADEMY", description : "Training center on information technologies, new management and support for young entrepreneurs to meet the challenges of digital transformation in Africa."},
                // {slug : "http://www.yayo-africa.com", title : "YAYO AFRIQUE", description : "Web platform and a mobile application, offering users the possibility to send phone credits and take care of their loved ones' bills remotely. RECHARGEYayo Recharge offers a secure digital payment service, available in more than "},
                // {slug : "https://dumasolutions.com/", title : "DUMA SOLUTIONS", description : "African fintech company on a mission to help drive financial innovation and build an efficient financial ecosystem in Africa.Our goal is to bridge the gap between traditional finance and digital financial services, by leveraging our cutting-edge technology and creating meaningful partnerships within the region"},
                // {slug : "https://www.icashbs.com", title : "ICASH Business Solutions", description : "Our unique event strives to create a place where industry experts and beginners can exchange ideas about the benefits of modern technology. Our goal is to share success stories as well as look to the future and discuss how we can continue to grow together in this new era of digital technology."},
                {
                    slug : "",
                    title : "Online course",
                    description : "We offer specialized IT-related workshops, focusing on training and management. These lessons give learners the knowledge and skills needed to effectively utilize information and communication technology in their everyday tasks."
                },
                {
                    slug : "",
                    title : "Emer'J Sponsorship",
                    description : "Through the Emerj Sponsorship Program, we strive to support our students and entrepreneurs in fostering the growth of their start-ups through creative innovation. This initiative offers a variety of tools to achieve your business ambitions."
                },
                {
                    slug : "",
                    title : "Future Entrepreneurs Program",
                    description : "This program, aimed at 20-25 year olds in rural and underprivileged districts of Africa, is centered on providing the tools and resources needed for entrepreneurship development."
                },
            ]
        }
    },
    participatePage : {
        title : "The 2023 Startup Expo Support Program",
        description : "Do you own a Start-up and want to expand your activities in Cameroon, Africa and beyond? The EMMER'J 2023 forum offers a start-up exhibition support program that gives you access to virtual and physical exhibition platforms.",
        rewards : {
            title : "The chosen startups will receive",
            data : [
                {
                    image : "/images/icons/emerj-reward.png",
                    title : "Maximize brand exposure",
                    description : "Increase the visibility and reliability of your organization to seize the opportunity to cultivate a presence in the sector you are targeting."
                },
                {
                    image : "/images/icons/emerj-teach.png",
                    title : "Exhibit your products",
                    description : "Demonstrate your solutions to your prospects, future investors and business partners."
                },
                {
                    image : "/images/icons/emerj-networking.png",
                    title : "An Unparalleled Networking Experience",
                    description : "Make valuable connections - mingle with the local and global digital community. Host virtual or face-to-face meetings with new contacts during the event."
                },
                {
                    image : "/images/icons/emerj-share-experience.png",
                    title : "Generation of new leads",
                    description : "Collect solid leads that turn into valuable business relationships. Our matchmaking tools are designed to help you identify and find the perfect partners, investors and prospects."
                }
            ]
        },
        criteria : {
            title : "Application criteria",
            data : [
                "Has a website available with basic information, e.g. company address, contact details",
                "The products and/or services provided in the Fintech-related segment(s), etc.",
                "Has been established for at least 6 months and no more than five years",
                "Guarantee the presence of at least one staff member on the virtual and/or physical stand for the duration of the event",
                "Provide finance/technology services"
            ]
        },
    },
    contactPage : {
        breadcrumbTitle : "Contact us"
    },
    pageNotFound : {
        text1 : "It seems like the page you're looking for just don't exist",
        text2 : { text : "get back to the", brillant : "home page" }
    },
    registerNow : "Register now",
    days : { monday : "Monday", tuesday : "Tuesday", wednesday : "Wednesday", thursday : "Thurday"},
    applyNow : "Register Now"
}

export const TEXT_FR : TextContent = {
    menu : [
        { label : "Accueil", link : "/"},
        // { label : "Intervenants", link : "/speakers"},
        { label : "A propos de nous", link : "/about-us"},
        // { label : "Emer'J Tour", link : "/emerj-tour"},
        { label : "Participer", link : "/participate"},
        { label : "Contact", link : "/contact"},
    ],
    homePage : {
        hero : {
            title : "EMMER'J FORUM 2023",
            subTitle : "Leadership, Transformation Numérique & Innovation",
            p : "26-28 Avril Douala, Cameroun"
        },
        higlights : {
            title : "Faites partie du mouvement qui révolutionne l'entrepreneuriat en Afrique.",
            data : [
                { label : "3 Jours", icon : "timing.png"},
                { label : "2 Webinaires", icon : "schema.png"},
                { label : "20+ Intervenants", icon : "speakers.png"},
                { label : "5+ Panels de Discussion", icon : "messages.png"},
                { label : "Solution Demos", icon : "teaching.png"},
                { label : "Masterclasses", icon : "masterclasse.png"},
            ]

        },
        attend : {
            section1 : {                
                title  : "Pourquoi participer ?",
                data : [
                    "Pour comprendre en profondeur le développement et les avantages de la transformation numérique en Afrique.",
                    "Entrez en contact avec les acteurs influents de l'Académie EMMER'J et de la transformation numérique, les créateurs de tendances du mouvement numérique en Afrique.",
                    "Assistez à des expositions de produits et de services qui révolutionnent le domaine des paiements, des transferts, de la communication, du marketing numérique, et plus encore.",
                ]
            },
            section2 : {
                title : "Qui Particippent ?",
                data1 : [
                    { label : "Institutions financières", icon : "emerj-financial-institutions.png"},
                    { label : "Régulateurs", icon : "iemerj-regulators.png"},
                    { label : "Fintechs", icon : "emerjs-fintechs.png"},
                    { label : "Solutions technologiques", icon : "masterclasse.png"},
                    { label : "Industries Techs", icon : "emerj-tsp.png"},
                    { label : "Instances dirigeantes", icon : "emerj-bodies.png"},
                    { label : "Investisseurs", icon : "emerj-investors.png"},
                ],
                description : "Compte tenu de l'accent mis sur la transformation numérique, des entités de toutes sortes ont désormais fait de l'inclusion financière en Afrique une priorité. Par conséquent, nous réunissons des experts d'une grande variété de secteurs pour collaborer sur cette question.",
                data2 : [
                    "Leaders de l'innovation et de la transformation numérique",
                    "Décideurs clés des entreprises",
                    "Fondateurs et PDG de Fintechs",
                    "Entrepreneurs",
                    "Cadres dirigeants d'institutions"
                ]
            }
        },
        sponsors : {
            title : "Nos Sponsors"
        }
    },
    speakersPage : {
        breadcrumbTitle : "Our speakers",
        mainTitle : { part1 : "Who", part2 : "Speaking" },
        description : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusm tempor incididunt ut labore dolore magna aliqua enim ad minim veniam quis nostrud exercitation ullamco.",
        speakers : [
            {
                name : "Gloire LOMAMI",
                position : "VP ICASHBS",
                image : "/images/speakers/emerj-forum-gloire-lomami.png",
                biography : "Si j’avais à me décrire en quelques lignes, je dirais que je suis une personne remplie d’audace, de confiance et de potentiel que je m’efforce de faire croître dans la mesure du réalisable. Épris de passion pour les casse-têtes, j’ai su trouver dans le domaine des TIC matière à la hauteur de mon appétit.\nJ’adore la gestion et la réalisation des projets. Parfois je me perds entre mes chapeaux de Gestionnaire, architecte de solutions et de Lead dev. Je crois en la force d'une équipe soudée et je m'évertue à créer un environnement optimal et agréable de travail.\nJ’adore autant partager que recevoir.\nCe qui fait ma particularité, en tant que personne, c’est mon respect pour autrui, ma dynamique de vie, mon sens des responsabilités et surtout mon sens prononcé d’humour. Pour demain, je veux faire grandir mes entreprises et impacter positivement ceux qui m’entourent.",
                linkedin : "https://www.linkedin.com/in/lom-s-lomami-b95b7127/",
            },
            {
                name : "Emmanuel LOMAMI",
                position : "Managing Director ICASHBS",
                image : "/images/speakers/emerj-forum-emmanuel-lomami.png",
                biography : "An enthusiastic and dedicated IT professional with over 10 years' experience in project and team management with comprehensible expertise in directing the implementation of wide variety of complex IT Systems and solutions. Piloted and worked part of the sophisticated technology projects, developing product requirements, and analysing support strategies and processes to facilitate improved operations and efficiency. Applied expertise in developing and leading projects and initiatives for major commercial and international organisations including Citibank, Almac group, Verint, Weatherbys Bank, Barclaycard, Alliance Irish Bank (AIB) and TPICAP. Provided essential consultative support and building key client relationships through effective interpersonal, organisational and leadership abilities. With a proven track record as a freelancer, in leading project management teams through technical installations and development cycles of systems and programs for a broad range of clients such as icashbs.co.uk, kingsridgecity.com, invesperia.ca, yayo-africa.com and icash-congo.com. Possess excellent interpersonal skills and experience of liaising with the business with strong communication skills and can communicate concisely at all levels. Combined with problem solving skills, has been proven essential to excel in driving teams through all facets of project life cycles and ensuring optimal client experiences.",
                linkedin : "https://www.linkedin.com/in/emmanuel-kasongo-a1581411/",
            },
            // {
            //     name : "Vanessa MBAMARAH",
            //     position : "Founder Ztallion",
            //     biography : "Vanessa Is a Marketing and Transformation Specialist, a speaker, trainer, philanthropist and a digital enthusiast who is passionate about bridging the digital skills gap in Africa. After undergoing the Google Digital Skills training in 2016, Vanessa went on to pioneer the #DigifyBytes Training Program in Cotonou Benin, an initiative of Google to train 1 Million Africans on Digital skills in 2016. Six months later, she was selected and featured as one of Google’s First Success story on the Google Digital Skills Program in Africa, before becoming a training partner on the same program representing Francophone Africa.",
            //     linkedin : "https://www.linkedin.com/in/vmbamarah/",
            //     image : "/images/speakers/emerj-forum-vanessa-mbamarah.jpg",
            // },
            {
                name : "Dicko SY",
                position : "CO-FONDATRICE DICTAF",
                biography : "Agronome et communicante, Dicko est une jeune femme qui a décidé de consacrer toute sa vie au développement de l'agriculture Sénégalaise. Diplômée de l'université Gaston Berger de Saint-Louis en sciences agronomiques, elle s'est vite spécialisée en production végétale afin de voir comment participer à la hausse des rendements de récolte pour conduire le pays à l'autosuffisance alimentaire. Particulièrement sensible à l'implication des femmes dans le processus de développement, Dicko encourage les filles à se lancer dans l'entreprenariat dans le secteur agricole. Plusieurs distinctions lui ont été décernées au courant de son combat, l'on peut citer le Prix de l'entreprenariat numérique féminin de la Sonatel: Linguere Digital Challenge. Elle fait partie des Cinq jeunes entrepreneurs sénégalais sur 1000 Africains qui ont été retenus par la Fondation Tony Elumelu dans le cadre de la 3ème édition du Programme d’entreprenariat dénommé Tony Elumelu entrepreneurship programme (Teep). Ambitieuse, dynamique avec une joie de vivre débordante, elle inspire confiance aux clients et partenaires.",
                linkedin : "https://www.linkedin.com/in/dicko-sy-60363b111/",
                image : "/images/speakers/emerj-forum-dicko-sy.jpeg",
            },
            {
                name : "Jean-François BOILY",
                position : "DÉVELOPPER SÉNIOR",
                biography : "Jean-François est passionné de programmation depuis… très très longtemps. Diplômé de l’Université de Sherbrooke en 1997, son parcours professionnel l’a mené en jeux vidéo, web, consultation et enseignement, suivant le fil de ses intérêts. Toujours porté par l’innovation, l’apprentissage et le partage, il sait transmettre sa passion et son entrain est contagieux.",
                linkedin : "https://www.linkedin.com/in/jeanfrancoisboily/",
                image : "/images/speakers/emerj-forum-jean-francois-boilly.jpeg",
            },
            {
                name : "Laurence GRAVEL",
                position : "Scrum Master, Project Lead chez Vanderlande",
                biography : "En tant que Scrum Master, mon rôle est d'assurer une bonne collaboration entre nos équipes à l'international et de contribuer à une transformation vers le management Agile. Mon objectif est de faire passer le développement logiciel au niveau supérieur en coachant les équipes vers l'adoption de pratiques de travail plus efficaces. Je dois également m'assurer de construire un environnement de confiance et de sécurité au sein des équipes pour favoriser les discussions, la prise de décisions et la résolution de conflits. Je suis animée par l'ambition de devenir la meilleure version de moi-même et une meilleure leader pour mes pairs. Je crois que je peux réaliser mes ambitions en apprenant des gens intelligents et compétents qui m'entourent. Car l'excellence est un effort d'équipe.",
                linkedin : "https://www.linkedin.com/in/laurence-gravel-32887ba5/",
                image : "/images/speakers/emerj-forum-laurence-gravel.jpg",
            },
            {
                name : "Maxime BELANGER",
                position : "Sofwtare developpeur sénior",
                biography :  "",
                linkedin : "https://www.linkedin.com/in/maximebelanger",
                image : "/images/speakers/emerj-forum-maxime-belanger.jpg",
            },
            {
                name : "Elyy NDIMBU",
                position : "Fondateur Groupe Kin’s Dreams",
                biography :  "",
                linkedin : "https://www.linkedin.com/in/elie-ndibu-228270145/",
                image : "/images/speakers/emerj-forum-ely-ndimbu.jpg",
            }
        ]
    },
    aboutUsPage : {
        title : "A propos de Emer'J",
        descriptionAbout : "Emerj Academy organise du 26 au 28 avril 2023 le 'Emerj Forum', un événement de trois jours consacré à la présentation de la Perspective Ground Zero de la Transformation Digital. De plus, les participants auront l'occasion d'explorer divers sujets liés aux secteurs Fintech, aux pratiques commerciales et technologiques, ainsi qu'aux réglementations et politiques qui permettent aux entrepreneurs de prospérer à l'échelle mondiale. Ce rassemblement incitant à la réflexion comprendra des tables rondes, des démonstrations de solutions, des stands d'exposition avec les derniers produits et des discussions engageantes au coin du feu.",
        organisation : {
            title : "",
            description : "Emer'J Academy s'engage à donner aux entrepreneurs africains en herbe les moyens de répondre aux exigences en constante évolution de l'espace numérique. Fondée dans le but de donner aux entrepreneurs le savoir-faire et les compétences nécessaires pour réussir la transformation numérique, nous proposons une gamme de cours couvrant les pratiques informatiques et de gestion pour donner à quiconque l'ambition et le dévouement nécessaires pour acquérir les connaissances nécessaires pour réussir. Notre programme unique garantit que nos étudiants ont les outils et la confiance nécessaires pour exceller dans le domaine de leur choix, entraînant des changements positifs dans leur communauté locale. Avec Emer'J Academy, les jeunes entrepreneurs ont la possibilité de devenir des experts de l'industrie de la transformation numérique en Afrique - nous vous invitons à nous rejoindre et à réaliser vos rêves d'entrepreneur.",
            // description2 : "Nous avons une équipe talentueuse capable de gérer tout projet de A à Z en garantissant la qualité et en respectant les contraintes de temps.  Avec notre passion pour la diversité, ICASH Business Solutions vise à donner de l'autonomie aux individus et aux entreprises grâce à nos services TI et nos solutions de paiement qui soutiennent les objectifs de transformation numérique de nos clients.",
            data : ["Réalisez vos rêves d'entrepreneur avec Emer'J Academy", "Développer les compétences nécessaires pour réussir dans l'industrie de la transformation numérique", "Cursus unique qui apporte le savoir-faire et les outils nécessaires", "Faites partie d'une communauté grandissante d'entrepreneurs africains prospères"]

        },
        activities : {
            title : "Nos Activités",
            data : [
                { slug : "http://www.yayo-africa.com", title : "EMMER'J ACADEMY", description : "centre de formation sur les technologies de l’information, le nouveau management et l'accompagnement des jeunes entrepreneurs afin de répondre aux enjeux de transformation numérique en Afrique."},
                // { slug : "http://www.yayo-africa.com", title : "YAYO AFRIQUE", description : "Plateforme web et une application mobile, offrant aux utilisateurs la possibilité d'envoyer des crédits téléphoniques et de s'occuper des factures de leurs proches à distance.RECHARGEYayo Recharge offre un service de paiement numérique sécurisé, disponible dans plus de "},
                // { slug : "https://dumasolutions.com/", title : "DUMA SOLUTIONS", description : "Société africaine de technologie financière dont la mission est de stimuler l’innovation financière et de construire un écosystème financier efficace en Afrique.Notre objectif est de combler le fossé entre la finance traditionnelle et les services financiers numériques, en tirant parti de notre technologie de pointe et en créant des partenariats significatifs dans la région"},
                // { slug : "https://www.icashbs.com", title : "EMMER'J FORUM", description : "Notre événement unique s'efforce de créer un lieu où les experts du secteur et les débutants peuvent échanger des idées sur les avantages de latechnologie moderne. Notre objectif est de partager des histoires de réussite ainsi que de regarder vers l'avenir et de discuter de la manière dont nous pouvons continuer à grandir ensemble dans cette nouvelle ère de la technologie numérique."},
                {
                    slug : "",
                    title : "Cours en ligne",
                    description : "Nous offrons des ateliers spécialisés liés à l'informatique, axés sur la formation et la gestion. Ces leçons donnent aux apprenants les connaissances et les compétences nécessaires pour utiliser efficacement les technologies de l'information et de la communication dans leurs tâches quotidiennes."
                },
                {
                    slug : "",
                    title : "Parrainage Emer'J",
                    description : "Grâce au programme de parrainage Emer'J', nous nous efforçons d'aider nos étudiants et entrepreneurs à favoriser la croissance de leurs start-ups grâce à l'innovation créative. Cette initiative offre une variété d'outils pour réaliser vos ambitions commerciales."
                },
                {
                    slug : "",
                    title : "Programme Futurs Entrepreneurs",
                    description : "Ce programme, destiné aux 20-25 ans des zones rurales et défavorisées d'Afrique, est centré sur la fourniture des outils et des ressources nécessaires au développement de l'entrepreneuriat."
                },
            ]
        }
    },
    participatePage : {
        title : "Le programme de soutien aux expositions de startups de 2023",
        description : "Vous êtes propriétaire d'une Start-up et souhaitez étendre vos activités au Cameroun, en Afrique et au-delà ? Le forum EMMER'J 2023 propose un programme de soutien à l'exposition des start-ups qui vous donne accès à des plateformes d'exposition virtuelles et physiques.",
        rewards : {
            title : "Les projets séléctionnés bénéficieront",
            data : [
                {
                    image : "/images/icons/emerj-reward.png",
                    title : "Maximiser l'exposition aux marques",
                    description : "Augmentez la visibilité et la fiabilité de votre organisation pour saisir l'opportunité de cultiver une présence dans le secteur que vous ciblez."
                },
                {
                    image : "/images/icons/emerj-teach.png",
                    title : "Exposez vos produits",
                    description : "Démontrez vos solutions à vos prospects, futurs investisseurs et partenaires commerciaux."
                },
                {
                    image : "/images/icons/emerj-networking.png",
                    title : "Une expérience de mise en réseau inégalée",
                    description : "Nouez des liens précieux - mêlez-vous à la communauté numérique locale et mondiale. Organisez des réunions virtuelles ou en face à face avec de nouveaux contacts pendant l'événement."
                },
                {
                    image : "/images/icons/emerj-share-experience.png",
                    title : "Génération de nouveaux prospects",
                    description : "Recueillez des pistes solides qui se transforment en relations commerciales précieuses. Nos outils de mise en relation sont conçus pour vous aider à identifier et à trouver les partenaires, investisseurs et prospects parfaits."
                }
            ]
        },
        criteria : {
            title : "Critères d'application",
            data : [
                "Possède un site web disponible avec des informations de base, par exemple l'adresse de l'entreprise, ses coordonnées",
                "les produits et/ou services fournis dans le(s) segment(s) lié(s) aux Fintech, etc",
                "Est établie depuis au moins 6 mois et pas plus de cinq ans",
                "Garantir la présence d'au moins un membre du personnel sur le stand virtuel et/ou physique pendant toute la durée de l'événement",
                "Fournir des services dans le domaine de la finance/technologie"
            ]
        },
    },
    contactPage : {
        breadcrumbTitle : "Contactez nous"
    },
    pageNotFound : {
        text1 : "il nous semble la page que vous recherchez n'existe pas",
        text2 : { text : "retourner sur la page", brillant : "d'accueil" }
    },
    registerNow : "Enregistrez vous",
    days : { monday : "Lundi", tuesday : "Mardi", wednesday : "Mercredi", thursday : "Jeudi"},
    applyNow : "S'inscrire Maintenant"
}