import { Link, NavLink, useLocation } from "react-router-dom";
import Container from "./container";
import Logo from "./logo.component";
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../contexts/global.context';

export default function Header(){
    const {TEXTS} = useContext(GlobalContext)
    const location = useLocation()
    const [showMobileMenu, setShowMobileMenu] = useState(false)
    
    useEffect(() =>{
        setShowMobileMenu(false)
        if(location.hash){
            document.getElementById(location.hash.substring(1))?.scrollIntoView({behavior : "smooth", inline : "start" })
        }else{
            window.scrollTo( 0, 0 )
        }

        const onscroll = () => setShowMobileMenu(false)
        const onclick = () => setShowMobileMenu(false)
        window.addEventListener("scroll", onscroll)
        window.addEventListener("click", onclick)

        return () => window.removeEventListener("scroll", onscroll)

    }, [location])

    return (
        <header className="sticky z-40 top-0 w-full py-2 bg-white border-b-2 border-secondary border-opacity-30">
            <Container className="flex items-center justify-between">
                <Logo />
                <button onClick={ e => { e.stopPropagation(); setShowMobileMenu( prev => !prev) }} className={`md:hidden rounded w-8 h-8 flex justify-center items-center text-white fas ${ showMobileMenu ? "fa-close bg-primary" : "fa-bars text-secondary"} text-xl font-bold `} />
                <div className={`${ showMobileMenu ? "flex flex-col w-52 h-fit right-0 shadow-lg rounded-tl rounded-bl" : "hidden h-0 w-0 -right-full"} overflow-hidden absolute top-[3.55rem] md:top-0 md:right-0 p-5 bg-gray-200 md:bg-transparent md:p-0 md:h-fit md:w-fit md:relative md:flex md:flex-row md:items-center gap-x-10`}>
                    <nav className="flex flex-col md:flex-row md:items-center gap-6">
                        {
                            TEXTS.menu.map( ({label, link}, index) => (
                                <NavLink key={index} className={ ({isActive}) => `text-lg ${isActive ? "text-red-400" : "text-secondary-deep"}`} to={link}>{label}</NavLink>
                            ))
                        }
                    {/* <Link to="" className="h-fit py-3 px-6 bg-secondary-deep hover:bg-primary text-white rounded">{TEXTS.registerNow}</Link> */}
                    </nav>

                </div>
            </Container>
        </header>
    )
}